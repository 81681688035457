import React, { useEffect } from "react";
import type { NextPage } from "next";
import Head from "next/head";
import PageLayout from "../components/PageLayout";
import useSession from "../hooks/useSession";
import Card from "../components/Card";
import {
  FaFacebookF as FacebookIcon,
  FaInstagram as InstagramIcon,
} from "react-icons/fa";
import { ImSpinner9 as SpinnerIcon } from "react-icons/im";
import Script from "next/script";
import { useRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";
import ErrorToast from "../components/ErrorToast";

const ConfirmationPage: NextPage = () => {
  const { id } = useRouter().query;
  const { session, queryStatus } = useSession(id as string | undefined);

  useEffect(() => {
    if (session?.id && session?.email) {
      process.env.NEXT_PUBLIC_ENABLE_TRACKING === "true" &&
        (window as any).fbq("track", "Purchase", {
          id: session.id,
          email: session.email,
          orderNumber: session.orderNumber,
        });
    }
  }, [session?.id, session?.email, session?.orderNumber]);

  return (
    <div>
      <Script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/sv_SE/sdk.js#xfbml=1&version=v13.0&appId=364813068366078&autoLogAppEvents=1"
        nonce="HJd7T4ku"
      />
      <div id="fb-root"></div>
      <Head>
        <title>
          Tack för din anmälan till Göteborgs Brantaste{" "}
          {process.env.NEXT_PUBLIC_RACE_YEAR}
        </title>
        <meta name="robots" content="noindex" />
        <link rel="icon" href="/favicon.ico" />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=Purchase&noscript=1`}
          />
        </noscript>
      </Head>
      <main>
        <PageLayout>
          <div className="px-4 md:px-8 pt-6 pb-8 mb-4">
            {queryStatus === "loading" ? (
              <Card>
                <h2 className="mb-4 text-xl text-center">
                  Laddar... Vänligen stanna på sidan
                </h2>
                <SpinnerIcon
                  className={`animate-spin w-20 h-20 text-gray-500 m-auto`}
                />
              </Card>
            ) : queryStatus === "error" ? (
              <ErrorToast
                id={uuidv4()}
                title="Ett fel inträffade!"
                message="Din betalning kunde inte verifieras. Kontakta oss via mail på support@goteborgsbrantaste.se så hjälper vi dig!"
                duration={120000}
              />
            ) : queryStatus === "success" && session?.id ? (
              <Card>
                <h2 className="text-xl mb-1">
                  Tack för din anmälan till Göteborgs Brantaste{" "}
                  {process.env.NEXT_PUBLIC_RACE_YEAR}!
                </h2>
                <div>
                  <span className="text-sm">
                    {session.paymentType === "Invoice" ? `Vi kommer kontakta ${session.email} för faktureringsinformation.` : `Du kommer inom kort att få ett kvitto till ${session?.email}.`}
                  </span>
                </div>
                <div>
                  <span className="text-sm">
                    Ditt ordernummer är {session?.orderNumber}
                  </span>
                </div>
                <div className="mt-8">
                  <h2 className="text-lg mb-1">
                    Det blir roligare tillsammans!
                  </h2>
                  <span className="text-sm">
                    Utmana dina vänner till en tuff & rolig aktivitet som alla
                    kan klara av! Ange samma lagnamn vid anmälan så får ni en
                    picknickkasse att dela på efter målgång
                  </span>
                  <div className="block mt-1">
                    <div
                      className="fb-share-button"
                      data-href="https://goteborgsbrantaste.se"
                      data-layout="button"
                      data-size="large"
                    >
                      <a
                        target="_blank"
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fgoteborgsbrantaste.se%2F&amp;src=sdkpreparse"
                        className="fb-xfbml-parse-ignore"
                        rel="noreferrer"
                      >
                        Dela
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-8">
                  <h2 className="text-lg mb-1">
                    Har du några frågor gällande din anmälan?
                  </h2>
                  <span className="text-sm">
                    Du når oss bäst på Facebook eller Instagram. Gilla och följ
                    sidorna så missar du ingen information
                  </span>
                </div>
                <div className="flex gap-4 mt-2">
                  <a
                    href="https://www.facebook.com/goteborgsbrantaste"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FacebookIcon className="w-10 h-10 text-white bg-[#1877f2] hover:scale-105 hover:cursor-pointer p-1 rounded-md" />
                  </a>
                  <a
                    href="https://www.instagram.com/goteborgsbrantaste/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <InstagramIcon className="w-10 h-10 hover:scale-105 hover:cursor-pointer bg-gradient-to-tr from-[#feda75] via-[#d62976] via to-[#4f5bd5] text-white p-1 rounded-md" />
                  </a>
                </div>
              </Card>
            ) : null}
          </div>
        </PageLayout>
      </main>
    </div>
  );
};

export default ConfirmationPage;
